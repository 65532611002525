<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import router from "@/router";

export default {
  name: 'candidat-email-new',
  components: {
    TopNavbar
  },
  data() {
    return {
      email: null,
      flash: null,
      lastName: null,
      name: null,
      displayCompleteForm: false,
    }
  },
  mounted() {
  },
  methods: {
    searchUser() {
      if (this.email) {
        if (this.validateEmail(this.email)) {
          axiosApiInstance.post(process.env.VUE_APP_API_URL + '/check-company-user-relation', {email: this.email}).then(response => {
            if (response.data.relation === true) {
              this.checkIfWayIsFinish()
            } else {
              this.displayCompleteForm = true
            }
          }).catch(() => {
            this.updateFlash("Une erreur est survenue.", 'danger')
          })
        }
      }
    },
    CreateCandidateUser() {
      axiosApiInstance.post(process.env.VUE_APP_API_URL + '/create-candidate-user-simplify', {
        name: this.name,
        lastName: this.lastName,
        email: this.email
      }).then(response => {
        if (response.data.saved === true) {
          this.createLink()
        } else {
          this.updateFlash("Une erreur est survenue.", 'danger')
        }
      }).catch((e) => {
        this.updateFlash(e.response.data, 'danger')
      })
    },
    createLink() {
      axiosApiInstance.post(process.env.VUE_APP_API_URL + '/create-candidate-link', {email: this.email}).then(response => {
        if (response.data.created === true) {
          this.updateFlash('Le lien a été envoyé l\'utilisateur par email à l\'adresse: ' + this.email, 'success')
          this.returnToList()
          setTimeout(() => {
            this.resetForm()
          }, 3000)
        }
      }).catch(() => {
        this.updateFlash("Une erreur est survenue.", 'danger')
      })
    },
    checkIfWayIsFinish() {
      axiosApiInstance.post(process.env.VUE_APP_API_URL + '/check-way-finish', {email: this.email}).then(response => {
        if (response.data.finished === true) {
          this.downloadWay(response.data.way, response.data.user)
          this.returnToList()
        } else {
          this.updateFlash("Un email a été envoyé", 'success')
          this.returnToList()
        }
      })
    },
    validateEmail(mail) {
      if (/^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)) {
        return true
      }
      this.updateFlash("Email non valide", 'danger')
      return false
    },
    updateFlash(message, type) {
      this.flash = {message: message, type: type}
      setTimeout(() => {
        this.clearFlash()
      }, 3000)
    },
    clearFlash() {
      this.flash = null
    },
    resetForm() {
      this.email = null
      this.lastName = null
      this.name = null
      this.displayCompleteForm = false
    },
    returnToList(){
      router.push('/candidat/email')
    },
    downloadWay(id, candidateUser) {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/admin/company_pdf/' + id, {responseType: 'blob'}).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('A')
        link.href = URL.createObjectURL(blob)
        link.download = 'Way entreprise '+ id + ' ' + candidateUser +'.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(e => {
        this.updateFlash(e.message, 'danger')
      })
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="position-fixed top-0 start-50 translate-middle-x p-3" style="z-index: 11;padding-top:33px !important;">
      <div :class="'toast bg-' + flash.type + ' ' + (flash?'show':'hide')" v-if="flash">
        <div class="d-flex">
          <div class="toast-body">
            {{ flash.message }}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                  aria-label="Fermer" @click="clearFlash"></button>
        </div>
      </div>
    </div>

    <side-bar>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <div class="content">
        <h1>Proposer Someway à un candidat</h1>
        <button class="btn btn-primary mb-5" @click="returnToList()">Retour à la liste</button>
        <div class="mb-3" v-if="!displayCompleteForm">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" id="email" placeholder="john@doe.fr" v-model="email">
          <button class="btn btn-primary" @click="searchUser()" :disabled="email === null">Valider</button>
        </div>
        <div class="mb-3" v-else>
          <p>Il semble que cet utilisateur ne possède pas encore de compte.</p>
          <label for="name" class="form-label">Prénom</label>
          <input type="text" class="form-control" id="name" placeholder="John" v-model="name">

          <label for="lastName" class="form-label">Nom</label>
          <input type="text" class="form-control" id="lastName" placeholder="DOE" v-model="lastName">
          <button class="btn btn-primary" @click="CreateCandidateUser()"
                  :disabled="(name === null || lastName === null)">
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.toast-body {
  width: 90%;
}
</style>